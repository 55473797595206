<template>
  <div class="report-component-wrapper" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item-1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab-item class="mt-3 pb-1">
                  <v-row>
                    <v-col cols="12">
                      <v-select multiple
                                v-model="filters.person_types" label="Тип абонента"
                                :items="dispatcher_person_type_select" filled
                                color="grey" hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select multiple
                                v-model="filters.call_types" label="Тип дзвінка"
                                :items="dispatcher_call_type_select" filled
                                color="grey" hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select multiple
                                v-model="filters.priorities" label="Пріоритет"
                                :items="dispatcher_priority_select" filled
                                color="grey" hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select multiple
                                v-model="filters.statuses" label="Статус"
                                :items="dispatcher_status_select" filled
                                color="grey" hide-details
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-2">
                      <AddressElementSelect
                          label="Об’єднана громада" v-model="filters.amalgamated_hromada_id"
                          filled select_type="amalgamated_hromada"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-2">
                      <AddressElementSelect
                          label="Населений пункт" v-model="filters.city_id"
                          :parent_id="filters.amalgamated_hromada_id" :use_parent_id="true"
                          filled select_type="city"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <AddressElementSelect
                          label="Район міста" v-model="filters.city_area_id"
                          :parent_id="filters.city_id" :use_parent_id="true"
                          filled select_type="city-area"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2" v-if="filters.city_area_id">
                      <AddressElementSelect
                          label="Вулиця" v-model="filters.street_id"
                          :parent_id="filters.city_area_id" :use_parent_id="true"
                          filled select_type="street-with-city-area"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2" v-else>
                      <AddressElementSelect
                          label="Вулиця(-ці)" v-model="filters.street_id"
                          :parent_id="filters.city_id" :use_parent_id="true"
                          filled select_type="street"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <AddressElementSelect
                          label="Будинок(-ки)" v-model="filters.building_id"
                          :parent_id="filters.street_id" :use_parent_id="true"
                          filled select_type="building"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="filters.building_number" label="Номер будинку"
                                    filled hide-details color="grey"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="filters.building_number" label="Номер квартири"
                                    filled hide-details color="grey"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model.number="filters.entrance" label="Під'їзд"
                                    filled hide-details color="grey" v-integer
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model.number="filters.floor" label="Поверх"
                                    filled hide-details color="grey" v-integer
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-switch
                          v-model="filters.auto_width"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.auto_width ? 'По ширині сторінки (включений)' : 'По ширині сторінки (виключений)'"
                          @change="onFullWidthChange"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page"
         :class="{'page-a4-landscape-auto': filters.auto_width, 'page-a4-landscape-auto-full': !filters.auto_width}"
         style="height: auto !important; min-height: 215mm"
         :style="`zoom: ${scale / 100}`"
    >
      <div class="report" :style="`${filters.auto_width ? `transform: scale(${zoom}); transform-origin: top left;` : ''}`">
        <table>
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Історія диспетчерських викликів)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 10px;"></div>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 text-left mt-4" style="font-size: 1.06rem">
                Статистика по будинках
              </div>
            </div>
            <div class="empty-row report-header" style="height: 14px;"></div>
          </caption>
          <thead id="header">
            <tr>
              <th style="min-width: 500px; width: 500px;">Будинок</th>
              <th style="min-width: 120px;">Зареєстровано</th>
              <th style="min-width: 120px;">Опрацьовується</th>
              <th style="min-width: 120px;">Виконується</th>
              <th style="min-width: 120px;">Виконано</th>
              <th style="min-width: 120px;">Завершено</th>
              <th style="min-width: 120px;">Відмінено</th>
              <th style="min-width: 120px;">Всього</th>
            </tr>
          </thead>
          <tbody v-for="(item, idx) in items"
                 :key="item.index"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-${idx}`"
          >
          <template v-if="!item.items && by_grouping">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow" colspan="1"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-${idx}`)"
                        :id="`button-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <td>{{ item.registered | formatNumber }}</td>
              <td>{{ item.under_development | formatNumber }}</td>
              <td>{{ item.in_progress | formatNumber }}</td>
              <td>{{ item.completed | formatNumber }}</td>
              <td>{{ item.ended | formatNumber }}</td>
              <td>{{ item.canceled | formatNumber }}</td>
              <td>{{ item.total | formatNumber }}</td>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping">
            <tr>
              <td>{{ item.building_name }}</td>
              <td>{{ item.registered | formatNumber }}</td>
              <td>{{ item.under_development | formatNumber }}</td>
              <td>{{ item.in_progress | formatNumber }}</td>
              <td>{{ item.completed | formatNumber }}</td>
              <td>{{ item.ended | formatNumber }}</td>
              <td>{{ item.canceled | formatNumber }}</td>
              <td>{{ item.total | formatNumber }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="subitem.index"
            >
              <td>{{ subitem.building_name }}</td>
              <td>{{ subitem.registered | formatNumber }}</td>
              <td>{{ subitem.under_development | formatNumber }}</td>
              <td>{{ subitem.in_progress | formatNumber }}</td>
              <td>{{ subitem.completed | formatNumber }}</td>
              <td>{{ subitem.ended | formatNumber }}</td>
              <td>{{ subitem.canceled | formatNumber }}</td>
              <td>{{ subitem.total | formatNumber }}</td>
            </tr>
          </template>
          </tbody>
          <tr class="bg-level-0">
            <td class="grow" colspan="1" style="font-weight: 500; text-align: right;">Всього: </td>
            <td style="text-align: center;">{{ getTotal('items', 'registered') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items', 'under_development') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items', 'in_progress') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items', 'completed') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items', 'ended') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items', 'canceled') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items', 'total') | formatNumber }}</td>
          </tr>
        </table>

        <table>
          <caption>
            <div class="empty-row report-header" style="height: 26px;"></div>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 text-left mt-4" style="font-size: 1.06rem">
                Статистика по виконавцях
              </div>
            </div>
            <div class="empty-row report-header" style="height: 14px;"></div>
          </caption>
          <thead id="header-by-workers">
          <tr>
            <th style="min-width: 250px; width: 250px;">Працівник</th>
            <th style="min-width: 250px; width: 250px;">Посада</th>
            <th style="min-width: 120px;">Зареєстровано</th>
            <th style="min-width: 120px;">Опрацьовується</th>
            <th style="min-width: 120px;">Виконується</th>
            <th style="min-width: 120px;">Виконано</th>
            <th style="min-width: 120px;">Завершено</th>
            <th style="min-width: 120px;">Відмінено</th>
            <th style="min-width: 120px;">Всього</th>
          </tr>
          </thead>
          <tbody v-for="(item, idx) in items_by_workers"
                 :key="item.index"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-workers-${idx}`"
          >
          <template v-if="!item.items && by_grouping_by_workers">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow" colspan="2"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-workers-${idx}`)"
                        :id="`button-workers-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <td>{{ item.registered | formatNumber }}</td>
              <td>{{ item.under_development | formatNumber }}</td>
              <td>{{ item.in_progress | formatNumber }}</td>
              <td>{{ item.completed | formatNumber }}</td>
              <td>{{ item.ended | formatNumber }}</td>
              <td>{{ item.canceled | formatNumber }}</td>
              <td>{{ item.total | formatNumber }}</td>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping_by_workers">
            <tr>
              <td>{{ item.worker_name }}</td>
              <td>{{ item.position_name }}</td>
              <td>{{ item.registered | formatNumber }}</td>
              <td>{{ item.under_development | formatNumber }}</td>
              <td>{{ item.in_progress | formatNumber }}</td>
              <td>{{ item.completed | formatNumber }}</td>
              <td>{{ item.ended | formatNumber }}</td>
              <td>{{ item.canceled | formatNumber }}</td>
              <td>{{ item.total | formatNumber }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="subitem.index"
            >
              <td>{{ subitem.worker_name }}</td>
              <td>{{ subitem.position_name }}</td>
              <td>{{ subitem.registered | formatNumber }}</td>
              <td>{{ subitem.under_development | formatNumber }}</td>
              <td>{{ subitem.in_progress | formatNumber }}</td>
              <td>{{ subitem.completed | formatNumber }}</td>
              <td>{{ subitem.ended | formatNumber }}</td>
              <td>{{ subitem.canceled | formatNumber }}</td>
              <td>{{ subitem.total | formatNumber }}</td>
            </tr>
          </template>
          </tbody>
          <tr class="bg-level-0">
            <td class="grow" colspan="2" style="font-weight: 500; text-align: right;">Всього: </td>
            <td style="text-align: center;">{{ getTotal('items_by_workers', 'registered') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_workers', 'under_development') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_workers', 'in_progress') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_workers', 'completed') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_workers', 'ended') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_workers', 'canceled') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_workers', 'total') | formatNumber }}</td>
          </tr>
        </table>

        <table>
          <caption>
            <div class="empty-row report-header" style="height: 26px;"></div>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 text-left mt-4" style="font-size: 1.06rem">
                Статистика по проблемах
              </div>
            </div>
            <div class="empty-row report-header" style="height: 14px;"></div>
          </caption>
          <thead id="header-by-problems">
            <tr>
              <th style="min-width: 500px; width: 500px;">Проблема</th>
              <th style="min-width: 120px;">Зареєстровано</th>
              <th style="min-width: 120px;">Опрацьовується</th>
              <th style="min-width: 120px;">Виконується</th>
              <th style="min-width: 120px;">Виконано</th>
              <th style="min-width: 120px;">Завершено</th>
              <th style="min-width: 120px;">Відмінено</th>
              <th style="min-width: 120px;">Всього</th>
            </tr>
          </thead>
          <tbody v-for="(item, idx) in items_by_problems"
                 :key="item.index"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-problems-${idx}`"
          >
          <template v-if="!item.items && by_grouping_by_problems">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow" colspan="1"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-problems-${idx}`)"
                        :id="`button-problems-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <td>{{ item.registered | formatNumber }}</td>
              <td>{{ item.under_development | formatNumber }}</td>
              <td>{{ item.in_progress | formatNumber }}</td>
              <td>{{ item.completed | formatNumber }}</td>
              <td>{{ item.ended | formatNumber }}</td>
              <td>{{ item.canceled | formatNumber }}</td>
              <td>{{ item.total | formatNumber }}</td>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping_by_problems">
            <tr>
              <td>{{ item.call_date }}</td>
              <td>{{ item.registered | formatNumber }}</td>
              <td>{{ item.under_development | formatNumber }}</td>
              <td>{{ item.in_progress | formatNumber }}</td>
              <td>{{ item.completed | formatNumber }}</td>
              <td>{{ item.ended | formatNumber }}</td>
              <td>{{ item.canceled | formatNumber }}</td>
              <td>{{ item.total | formatNumber }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="subitem.index"
            >
              <td :style="`padding-left: ${subitem.level === 0 ? 8 : (item.level + 2) * 16}px`">
                {{ subitem.call_date }}
              </td>
              <td>{{ subitem.registered | formatNumber }}</td>
              <td>{{ subitem.under_development | formatNumber }}</td>
              <td>{{ subitem.in_progress | formatNumber }}</td>
              <td>{{ subitem.completed | formatNumber }}</td>
              <td>{{ subitem.ended | formatNumber }}</td>
              <td>{{ subitem.canceled | formatNumber }}</td>
              <td>{{ subitem.total | formatNumber }}</td>
            </tr>
          </template>
          </tbody>
          <tr class="bg-level-0">
            <td class="grow" colspan="1" style="font-weight: 500; text-align: right;">Всього: </td>
            <td style="text-align: center;">{{ getTotal('items_by_problems', 'registered') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_problems', 'under_development') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_problems', 'in_progress') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_problems', 'completed') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_problems', 'ended') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_problems', 'canceled') | formatNumber }}</td>
            <td style="text-align: center;">{{ getTotal('items_by_problems', 'total') | formatNumber }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {LANDSCAPE_ZOOM_WIDTH} from "@/utils/reports";
import ReportHouseAccountingComponentMixin from "@/mixins/report_house_accounting";
import {
  dispatcher_call_type_select,
  dispatcher_person_type_select,
  dispatcher_priority_select,
  dispatcher_status_select
} from "@/utils/icons";


export default {
  name: "dispatcher_calls_stats",
  mixins: [ReportHouseAccountingComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect')
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      dispatcher_person_type_select,
      dispatcher_call_type_select,
      dispatcher_priority_select,
      dispatcher_status_select,
      zoom_width: LANDSCAPE_ZOOM_WIDTH,
      filters: {
        person_types: [],
        call_types: [],
        priorities: [],
        statuses: [],
        amalgamated_hromada_id: null,
        city_id: null,
        city_area_id: null,
        street_id: null,
        building_id: null,
        entrance: null,
        floor: null,
        building_number: null,
        flat_number: null,
        auto_width: true,
      },
      grouping: [],
      items_by_workers: [],
      items_by_problems: [],
      max_lvl_by_workers: 0,
      max_lvl_by_problems: 0,
      by_grouping_by_workers: false,
      by_grouping_by_problems: false
    }
  },
  methods: {
    getTotal(table, col) {
      let total = 0

      let by_grouping = false

      if (table === 'items') by_grouping = this.by_grouping
      if (table === 'items_by_workers') by_grouping = this.by_grouping_by_workers
      if (table === 'items_by_problems') by_grouping = this.by_grouping_by_problems

      if (by_grouping) {
        total = this[table].filter(item => item.level === 0 && !item.items).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      } else {
        total = this[table].filter(item => !item.level).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      }
      return total
    },
    generate_rows(items, max_lvl, by_grouping, table_name='items') {
      this[table_name] = Object.freeze(items)
      if (table_name === 'items') {
        this.max_lvl = max_lvl
        this.by_grouping = by_grouping
      }
      if (table_name === 'items_by_workers') {
        this.max_lvl_by_workers = max_lvl
        this.by_grouping_by_workers = by_grouping
      }
      if (table_name === 'items_by_problems') {
        this.max_lvl_by_problems = max_lvl
        this.by_grouping_by_problems = by_grouping
      }

    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.dispatcher_calls_stats_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `dispatcher_calls_stats_${this.formatDate(this.date_start, 'MM_YYYY')}_${this.formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          email: this.email_address,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.dispatcher_calls_stats_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.dispatcher_calls_stats(payload)
            .then(response => response.data)
            .then(data => {
              this.items = []
              this.items_by_workers = []
              this.items_by_problems = []
              this.expand = false
              this.by_grouping = false
              this.by_grouping_by_problems = false
              this.by_grouping_by_workers = false
              this.max_lvl = 0
              this.max_lvl_by_problems = 0
              this.max_lvl_by_workers = 0
              this.generate_rows(data.rows_by_address, data.max_level, data.by_grouping, 'items')
              this.generate_rows(data.rows_by_workers, data.max_level_by_workers, data.by_grouping_by_workers, 'items_by_workers')
              this.generate_rows(data.rows_by_problems, data.max_level_by_problems, data.by_grouping_by_problems, 'items_by_problems')
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(this.max_lvl -1)
                this.getOnFullPage()
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць - має бути заповнений', color: 'error'})
      }
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
    onFullWidthChange(payload) {
      this.$nextTick(() => {
        if (payload) {
          this.getOnFullPage()
        }
      })
    },
  },
  created() {
    // reportAPI.report_settings("dispatcher_calls_history")
    //     .then(response => response.data)
    //     .then(data => {
    //       const server_filters = JSON.parse(data)
    //       Object.keys(this.filters).forEach(key => {
    //         if (server_filters[key] !== undefined) {
    //           this.filters[key] = server_filters[key]
    //         }
    //       })
    //       if (server_filters['grouping'] && this.grouping) {
    //         this.grouping.forEach(g => {
    //           g.on = !!server_filters.grouping.includes(g.value);
    //         })
    //       }
    //     })
    //     .finally(() => {
    //       this.$nextTick(() => this.getOnFullPage())
    //     })
  }
}
</script>

<style lang="scss" scoped>
</style>